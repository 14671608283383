<template>
    <slot/>
</template>

<script setup>
    import {useAuthStore} from "../stores/auth.js";
    import {onMounted, provide} from "vue";
    onMounted(async () => {
    });
</script>
